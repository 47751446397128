import * as React from 'react';

export const Lock = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <mask id="lockMask" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#lockMask)">
      <path d="M12.0001 1.375C13.3501 1.375 14.4918 1.84167 15.4251 2.775C16.3584 3.70833 16.8251 4.85 16.8251 6.2V8.15H17.9001C18.4168 8.15 18.8584 8.33333 19.2251 8.7C19.5918 9.06667 19.7751 9.50833 19.7751 10.025V19.875C19.7751 20.3917 19.5918 20.8333 19.2251 21.2C18.8584 21.5667 18.4168 21.75 17.9001 21.75H6.1001C5.58343 21.75 5.14176 21.5667 4.7751 21.2C4.40843 20.8333 4.2251 20.3917 4.2251 19.875V10.025C4.2251 9.50833 4.40843 9.06667 4.7751 8.7C5.14176 8.33333 5.58343 8.15 6.1001 8.15H7.1751V6.2C7.1751 4.85 7.6461 3.70833 8.5881 2.775C9.52943 1.84167 10.6668 1.375 12.0001 1.375ZM12.0001 3.25C11.1834 3.25 10.4878 3.53733 9.9131 4.112C9.33776 4.68733 9.0501 5.38333 9.0501 6.2V8.15H14.9501V6.2C14.9501 5.38333 14.6628 4.68733 14.0881 4.112C13.5128 3.53733 12.8168 3.25 12.0001 3.25ZM12.0001 13.025C11.4668 13.025 11.0128 13.2123 10.6381 13.587C10.2628 13.9623 10.0751 14.4167 10.0751 14.95C10.0751 15.4833 10.2628 15.9373 10.6381 16.312C11.0128 16.6873 11.4668 16.875 12.0001 16.875C12.5334 16.875 12.9878 16.6873 13.3631 16.312C13.7378 15.9373 13.9251 15.4833 13.9251 14.95C13.9251 14.4167 13.7378 13.9623 13.3631 13.587C12.9878 13.2123 12.5334 13.025 12.0001 13.025Z" fill="#2D2D2D"/>
    </g>
  </svg>
);
