import storage from 'src/storage';

const PITTA_AUTHORIZE_KEY = 'pitta_authorize';

interface IAuthorize {
  account: string;
  expiresIn: number;
  userPass: string;
  tokenType: string;
}

export const getAuthorizes = (): IAuthorize[] => (storage.getItem(PITTA_AUTHORIZE_KEY) || []) as IAuthorize[];

export const setAuthorize = (newAuthorize: IAuthorize): void => {
  const authorizes = getAuthorizes() as IAuthorize[];
  const filteredAuthorize = authorizes.filter((auth) => auth.account !== newAuthorize.account);
  const newList = [...filteredAuthorize, newAuthorize];
  storage.saveItem(PITTA_AUTHORIZE_KEY, newList);
};

export const removeAuthorize = (account: string): void => {
  const authorizes = getAuthorizes() as IAuthorize[];
  const filteredAuthorize = authorizes.filter((auth) => auth.account !== account);
  storage.saveItem(PITTA_AUTHORIZE_KEY, filteredAuthorize);
};
