import { combineReducers } from '@reduxjs/toolkit';

import loginReducer from 'src/features/login/slice';
import grantConsentReducer from 'src/features/grantConsent/slice';

const rootReducer = combineReducers({
  login: loginReducer,
  grantConsent: grantConsentReducer,
});

export default rootReducer;
