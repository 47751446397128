import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { color } from 'src/themes/color';

const Button = styled(MuiButton)({
  color: '#FFF',
  height: '52px',
  fontSize: '24px',
  boxShadow: 'none',
  borderRadius: '8px',
  '&.MuiButton-containedPrimary': {
    '&:hover': {
      background: color.green.dark
    }
  },
  '&.MuiButton-containedSecondary': {
    color: '#000',
  }
});

export default Button;
