import * as React from 'react';

export const Person = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <mask id="personMask" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#personMask)">
      <path d="M12.0001 12.0249C10.9334 12.0249 10.0208 11.6456 9.2621 10.8869C8.5041 10.1289 8.1251 9.21657 8.1251 8.1499C8.1251 7.06657 8.5041 6.1499 9.2621 5.3999C10.0208 4.6499 10.9334 4.2749 12.0001 4.2749C13.0834 4.2749 14.0001 4.6499 14.7501 5.3999C15.5001 6.1499 15.8751 7.06657 15.8751 8.1499C15.8751 9.21657 15.5001 10.1289 14.7501 10.8869C14.0001 11.6456 13.0834 12.0249 12.0001 12.0249ZM4.2251 19.6749V17.0249C4.2251 16.4749 4.36676 15.9706 4.6501 15.5119C4.93343 15.0539 5.30843 14.7082 5.7751 14.4749C6.79176 13.9749 7.8211 13.5956 8.8631 13.3369C9.90443 13.0789 10.9501 12.9499 12.0001 12.9499C13.0668 12.9499 14.1168 13.0789 15.1501 13.3369C16.1834 13.5956 17.2084 13.9749 18.2251 14.4749C18.7084 14.7082 19.0878 15.0539 19.3631 15.5119C19.6378 15.9706 19.7751 16.4749 19.7751 17.0249V19.6749H4.2251Z" fill="#2D2D2D"/>
    </g>
  </svg>
);
