import { createTheme } from '@mui/material/styles';
import { color } from './color';

export const pittaTheme = createTheme({
  palette: {
    primary: {
      main: color.green.primary,
    },
    secondary: {
      main: color.grey['500'],
    },
    error: {
      main: color.red.dark
    }
  },
});
