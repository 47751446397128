import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { queryClientCall, selectLogin } from 'src/features/login/slice';
import { routePathConfig } from 'src/routes/config';
import { ApiInvoked } from 'src/constants';

export const useClientVerification = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const {
    client_id: clientId,
    redirect_uri: redirectUri,
  } = queryParams;
  const {
    queryClient
  } = useAppSelector(selectLogin);

  React.useEffect(() => {
    const hasClientParameters = clientId && redirectUri;
    if (!hasClientParameters) {
      navigate(routePathConfig.error);
      return;
    }
    dispatch(queryClientCall({
      clientId, redirectUri,
    }));
  }, []);

  React.useEffect(() => {
    if (queryClient.status === ApiInvoked.FAILED) {
      navigate(routePathConfig.error);
    }
  }, [queryClient.status]);

  return;
};
