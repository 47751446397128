import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { routePathConfig } from 'src/routes/config';

const Home = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate(routePathConfig.error);
  }, []);

  return (
    <div>Home</div>
  );
};

export default Home;
