import * as React from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from 'src/store/hooks';
import { selectLogin, userVerification } from 'src/features/login/slice';
import { getAuthorizes, setAuthorize, removeAuthorize } from 'src/storage/authorize';
import { routePathConfig } from 'src/routes/config';
import { ApiInvoked } from 'src/constants';

export const useCheckUserPassInLocalStorage = () => {
  const dispatch = useAppDispatch();
  const {
    queryClient, postAuthorizeUser
  } = useAppSelector(selectLogin);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (queryClient.status === ApiInvoked.DONE) {
      const foundAuthorize = getAuthorizes()[0]; // Note: It is possible to store multiple userPass in the future, currently there is only one
      if (foundAuthorize) {
        const now = dayjs();
        const expiresAt = dayjs.unix(foundAuthorize.expiresIn);
        const isExpired = now.isAfter(expiresAt);
        if (isExpired) {
          removeAuthorize(foundAuthorize.account);
          return;
        }
        if (foundAuthorize.userPass) {
          dispatch(userVerification());
          navigate(routePathConfig.grantConsent);
          return;
        }
      }
    }
  }, [queryClient.status]);

  React.useEffect(() => {
    if (postAuthorizeUser.status === ApiInvoked.DONE) { // if user is valid
      const authorize = {
        account: postAuthorizeUser.data.user.account,
        expiresIn: postAuthorizeUser.data.expiresIn,
        userPass: postAuthorizeUser.data.userPass,
        tokenType: postAuthorizeUser.data.tokenType,
      };
      setAuthorize(authorize); // Save UserPass to Local Storage
      navigate(routePathConfig.grantConsent);
    }
  }, [postAuthorizeUser.status]);

  return;
};
