interface IAuthorize {
  account: string;
  expiresIn: number;
}

export const parseCookie = (): Record<string, string> => {
  const cookieObj: Record<string, string> = {};
  const cookies = document.cookie.split('; ');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const parts = cookie.split('=');
    const name = decodeURIComponent(parts[0]);
    const value = decodeURIComponent(parts[1]);
    cookieObj[name] = value;
  }
  return cookieObj;
};

export const getPittaRem = (): IAuthorize => {
  const cookie = parseCookie();
  return JSON.parse(cookie?.pitta_rem || '{}') as IAuthorize;
};

export const setPittaRem = ({ account, expiresIn }: { account: string; expiresIn: number }): void => {
  const expires = new Date(expiresIn * 1000).toUTCString();
  const rememberData = {
    account: account,
  };
  document.cookie = 'pitta_rem=' + JSON.stringify(rememberData) + '; expires=' + expires + '; path=/';
};
