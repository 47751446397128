import { color } from './color';
import { device } from './breakpoints';

export const darkTheme = {
  color: {
    ...color,
    backgroundColor: color.background.dark,
    textColor: color.text.dark,
  },
  device
};
