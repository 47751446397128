const saveItem = (key: string, item: unknown): void => {
  if (typeof localStorage === 'undefined') {
    return;
  }
  try {
    localStorage.setItem(key, JSON.stringify(item));
  } catch (e) {
    console.warn(e);
  }
};

const getItem = (key: string): unknown => {
  if (typeof localStorage === 'undefined') {
    return '';
  }
  if (!key) {
    throw new Error('should provide a valid key');
  }
  try {
    const item = localStorage.getItem(key);
    if (typeof item === 'string') {
      return JSON.parse(item);
    }
    return '';
  } catch (e) {
    console.warn(e);
  }
  return '';
};

const removeItem = (key: string): void => {
  if (typeof localStorage === 'undefined') {
    return;
  }
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.warn(e);
  }
};

const localStorageUtils = {
  saveItem,
  getItem,
  removeItem,
};

export default localStorageUtils;
