import * as React from 'react';

export const Error = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <mask id="errorMask" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#errorMask)">
      <path d="M12.025 13C12.275 13 12.4917 12.9083 12.675 12.725C12.8583 12.5417 12.95 12.3167 12.95 12.05V8C12.95 7.75 12.8583 7.53733 12.675 7.362C12.4917 7.18733 12.275 7.1 12.025 7.1C11.7583 7.1 11.5333 7.19167 11.35 7.375C11.1667 7.55833 11.075 7.775 11.075 8.025V12.075C11.075 12.3417 11.1667 12.5627 11.35 12.738C11.5333 12.9127 11.7583 13 12.025 13ZM12 16.875C12.2667 16.875 12.4917 16.7833 12.675 16.6C12.8583 16.4167 12.95 16.1917 12.95 15.925C12.95 15.6583 12.8583 15.4333 12.675 15.25C12.4917 15.0667 12.2667 14.975 12 14.975C11.7333 14.975 11.5083 15.0667 11.325 15.25C11.1417 15.4333 11.05 15.6583 11.05 15.925C11.05 16.1917 11.1417 16.4167 11.325 16.6C11.5083 16.7833 11.7333 16.875 12 16.875ZM12 21.75C10.65 21.75 9.38333 21.496 8.2 20.988C7.01667 20.4793 5.98333 19.7833 5.1 18.9C4.21667 18.0167 3.521 16.9833 3.013 15.8C2.50433 14.6167 2.25 13.35 2.25 12C2.25 10.6333 2.50433 9.35833 3.013 8.175C3.521 6.99167 4.21667 5.96233 5.1 5.087C5.98333 4.21233 7.01667 3.52067 8.2 3.012C9.38333 2.504 10.65 2.25 12 2.25C13.3667 2.25 14.6417 2.504 15.825 3.012C17.0083 3.52067 18.0377 4.21233 18.913 5.087C19.7877 5.96233 20.4793 6.99167 20.988 8.175C21.496 9.35833 21.75 10.6333 21.75 12C21.75 13.35 21.496 14.6167 20.988 15.8C20.4793 16.9833 19.7877 18.0167 18.913 18.9C18.0377 19.7833 17.0083 20.4793 15.825 20.988C14.6417 21.496 13.3667 21.75 12 21.75Z" fill="#DF534D"/>
    </g>
  </svg>
);
