import { styled } from '@mui/material/styles';
import MuiTextField from '@mui/material/TextField';

const TextField = styled(MuiTextField)({
  '& .MuiInputBase-root': {
    borderRadius: '8px'
  },
  '& .MuiInputBase-input': {
    height: '48px',
    boxSizing: 'border-box',
  },
  '& .MuiFormHelperText-root': {
    marginLeft: '0px'
  }
});

export default TextField;