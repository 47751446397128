export const snakeToCamel = (obj: object): object => {
  if (Array.isArray(obj)) {
    return obj.map(v => snakeToCamel(v));
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((result: Record<string, object>, key: string) => {
      const value = (obj as Record<string, object>)[key];
      const newKey = key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
      result[newKey] = snakeToCamel(value);
      return result;
    }, {});
  }
  return obj;
};

export const camelToSnake = (obj: object): object => {
  if (obj == null || typeof obj !== 'object') {
    return obj;
  }
  return Object.keys(obj).reduce((acc: Record<string, object>, key: string) => {
    const snakeCaseKey = key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    const value = (obj as Record<string, object>)[key];
    acc[snakeCaseKey] = camelToSnake(value);
    return acc;
  }, {});
};
