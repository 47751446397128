import axios, { AxiosRequestConfig } from 'axios';
import { getAuthorizes } from 'src/storage/authorize';

const stagingBaseURL = 'https://stg-core-api.cgm.org.tw';
const baseURL = process.env.REACT_APP_SERVER_URL || stagingBaseURL;

const SEPARATOR = ' ';

const makeAPI = (headers: AxiosRequestConfig['headers'], otherConfigs?: AxiosRequestConfig) => {
  const authorize = getAuthorizes()[0];
  const api = axios.create({
    baseURL,
    headers: {
      ...headers,
      'Authorization': authorize?.tokenType + SEPARATOR + authorize?.userPass,
    },
    ...otherConfigs
  });
  return api;
};

const ApiMethods = {
  get: (url: string, data?: unknown, headers = {}, otherConfigs?: AxiosRequestConfig) => {
    const api = makeAPI(headers, otherConfigs);
    return api.get(url);
  },
  post: (url: string, data: unknown, headers = {}, otherConfigs?: AxiosRequestConfig) => {
    const api = makeAPI(headers, otherConfigs);
    return api.post(url, data);
  },
  put: (url: string, data: unknown, headers = {}, otherConfigs?: AxiosRequestConfig) => {
    const api = makeAPI(headers, otherConfigs);
    return api.put(url, data);
  },
  delete: (url: string, data?: unknown, headers = {}, otherConfigs?: AxiosRequestConfig) => {
    const api = makeAPI(headers, otherConfigs);
    return api.delete(url);
  },
};

export default ApiMethods;
