import * as React from 'react';
import styled from 'styled-components/macro';
import { useAppSelector } from 'src/store/hooks';
import { selectLogin } from 'src/features/login/slice';
import { selectGrantConsent } from 'src/features/grantConsent/slice';

const Background = styled.div`
  background-color: ${props => props.theme.color.green.tint};
  width: 100%;
  height: 100vh;
  min-height: 850px;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  @media
  ${props => props.theme.device.mobile},
  ${props => props.theme.device.smMobile} {
    min-height: 100vh;
  }
`;

const MessageBox = styled.div`
  position: relative;
  margin-top: 144px;
  background-color: #FFFFFF;
  width: 100%;
  max-width: 540px;
  height: 248px;
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  padding: 60px 80px 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media
  ${props => props.theme.device.mobile},
  ${props => props.theme.device.smMobile} {
    margin: 0px;
    height: 100vh;
    max-width: 100vw;
    border-radius: 0px;
  }
  @media ${props => props.theme.device.mobile} {
    padding: 100px 28px 30px;
  }
  @media ${props => props.theme.device.smMobile} {
    padding-top: 60px 20px 20px;
  }
`;

const Title = styled.h1`
  font-size: 54px;
  font-weight: 700;
  margin: 0px;
  color: ${props => props.theme.color.green.dark};
`;

const ErrorCategory = styled.div`
  font-weight: 900;
  margin-top: 20px;
  font-size: 20px;
  color: ${props => props.theme.color.green.primary};
`;

const Description = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: ${props => props.theme.color.textColor};
`;

const Error = () => {
  const { queryClient } = useAppSelector(selectLogin);
  const { grantAuthCode } = useAppSelector(selectGrantConsent);
  const errorMsg = queryClient.error?.message || grantAuthCode.error?.message;

  return (
    <Background>
      <MessageBox>
        <Title>Oops!</Title>
        <ErrorCategory>Page Not Found</ErrorCategory>
        {!!errorMsg && <Description>{errorMsg}</Description>}
      </MessageBox>
    </Background>
  );
};

export default Error;
