import { AnyAction } from '@reduxjs/toolkit';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { epics as loginEpics } from 'src/features/login/slice';
import { epics as grantConsentEpics } from 'src/features/grantConsent/slice';
import { AppState } from './types';

export const epicMiddleware = createEpicMiddleware<AnyAction, AnyAction, AppState>();

export const rootEpic = combineEpics(
  ...loginEpics,
  ...grantConsentEpics,
);
