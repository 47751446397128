export const color = {
  green: {
    ally: '#277171',
    dark: '#358B8B',
    primary: '#4DADAD',
    light: '#80CCCC',
    tint: '#CFEFEF',
  },
  red: {
    ally: '#B63F3A',
    dark: '#DF534D',
    primary: '#EF6660',
    light: '#F4827D',
    tint: '#FBBCB9',
  },
  yellow: {
    ally: '#C6B02C',
    dark: '#EAD34B',
    primary: '#FFEF8F',
    light: '#FEF4B7',
    tint: '#FBF4CE',
  },
  grey: {
    '900': '#2D2D2D',
    '800': '#4B4B4B',
    '700': '#6F6F6F',
    '600': '#909090',
    '500': '#CCCCCC',
    '400': '#ECECEC',
    '300': '#F2F2F2',
    '200': '#F6F6F6',
    '100': '#F9F9F9',
  },
  background: {
    light: '#FDFDFD',
    dark: '#0D1010',
  },
  text: {
    light: '#283238',
    dark: '#ECF5F5',
  }
};
