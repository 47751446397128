import { color } from './color';
import { device } from './breakpoints';

export const defaultTheme = {
  color: {
    ...color,
    backgroundColor: color.background.light,
    textColor: color.text.light,
  },
  device
};
