import { createSlice, createAction } from '@reduxjs/toolkit';
import { ofType } from 'redux-observable';
import { of, from } from 'rxjs';
import { switchMap, catchError, throttleTime } from 'rxjs/operators';

import { RootState, AppEpic } from 'src/store/types';
import { getGrantedConsents, putGrantedConsents, postGenerateAuthorizationCode } from 'src/api/services';
import { snakeToCamel } from 'src/utils/common';
import { ApiInvoked } from 'src/constants';
import { TApiInvokedStatus } from 'src/types';

interface IConsents {
  consentKey: string;
  title: string;
  isMandatory: boolean
}

interface IClientRequestedConsents {
  categoryKey: string;
  title: string;
  consents: IConsents[];
}

export interface IState {
  queryConsent: {
    data: {
      clientRequestedConsents: IClientRequestedConsents[];
      userGrantedConsents: string[];
      isFullyGranted: boolean;
    },
    status: TApiInvokedStatus;
    error?: {
      error: string;
      message: string;
    }
  };
  putGrantedConsents: {
    data: {
      userGrantedConsents: IClientRequestedConsents[],
    };
    status: TApiInvokedStatus;
    error?: {
      error: string;
      message: string;
    }
  },
  grantAuthCode: {
    data: {
      tokenType: string;
      tokenSubject: string;
      expiresIn: number;
      authCode: string;
    };
    status: TApiInvokedStatus;
    error?: {
      error: string;
      message: string;
    }
  }
}

const initialState: IState = {
  queryConsent: {
    data: {
      clientRequestedConsents: [],
      userGrantedConsents: [],
      isFullyGranted: false,
    },
    status: ApiInvoked.IDLE,
    error: undefined,
  },
  putGrantedConsents: {
    data: {
      userGrantedConsents: [],
    },
    status: ApiInvoked.IDLE,
    error: undefined,
  },
  grantAuthCode: {
    data: {
      tokenType: '',
      tokenSubject: '',
      expiresIn: 0,
      authCode: '',
    },
    status: ApiInvoked.IDLE,
    error: undefined,
  }
};

export const slice = createSlice({
  name: 'grantConsent',
  initialState,
  reducers: {
    initGrantConsent: (state) => {
      state.queryConsent = initialState.queryConsent;
      state.putGrantedConsents = initialState.putGrantedConsents;
      state.grantAuthCode = initialState.grantAuthCode;
    },
    getGrantedConsentsCall: (state) => {
      state.queryConsent.status = ApiInvoked.LOADING;
    },
    getGrantedConsentsDone: (state, { payload }) => {
      state.queryConsent.data = payload;
      state.queryConsent.status = ApiInvoked.DONE;
    },
    getGrantedConsentsFailed: (state, { payload }) => {
      state.queryConsent.error = payload;
      state.queryConsent.status = ApiInvoked.FAILED;
    },
    putGrantedConsentsCall: (state) => {
      state.putGrantedConsents.status = ApiInvoked.LOADING;
    },
    putGrantedConsentsDone: (state, { payload }) => {
      state.putGrantedConsents.data = payload;
      state.putGrantedConsents.status = ApiInvoked.DONE;
    },
    putGrantedConsentsFailed: (state, { payload }) => {
      state.putGrantedConsents.error = payload;
      state.putGrantedConsents.status = ApiInvoked.FAILED;
    },
    postGenerateAuthorizationCodeCall: (state) => {
      state.grantAuthCode.status = ApiInvoked.LOADING;
    },
    postGenerateAuthorizationCodeDone: (state, { payload }) => {
      state.grantAuthCode.data = payload;
      state.grantAuthCode.status = ApiInvoked.DONE;
    },
    postGenerateAuthorizationCodeFailed: (state, { payload }) => {
      state.grantAuthCode.error = payload;
      state.grantAuthCode.status = ApiInvoked.FAILED;
    },
  }
});

export default slice.reducer;

/**
 *  Selectors
 */
export const selectGrantConsent = (state: RootState) => state.grantConsent;

/**
 *  Actions
 */
export const getGrantedConsentsCall = createAction<object>('grantConsent/getGrantedConsentsCall');
export const putGrantedConsentsCall = createAction<object>('grantConsent/putGrantedConsentsCall');
export const postGenerateAuthorizationCodeCall = createAction<object>('grantConsent/postGenerateAuthorizationCodeCall');
export const {
  initGrantConsent,
  getGrantedConsentsDone, getGrantedConsentsFailed,
  putGrantedConsentsDone, putGrantedConsentsFailed,
  postGenerateAuthorizationCodeDone, postGenerateAuthorizationCodeFailed
} = slice.actions;

/**
 *  Epics
 */
export const getGrantedConsentsEpic: AppEpic = (action$) => action$.pipe(
  ofType(getGrantedConsentsCall.type),
  throttleTime(500),
  switchMap(({ payload = {} }) => {
    return from(getGrantedConsents(payload)).pipe(
      switchMap(response => {
        const responseData = snakeToCamel(response.data);
        return of(getGrantedConsentsDone(responseData));
      }),
      catchError((error) => of(getGrantedConsentsFailed(error)))
    );
  })
);

export const putGrantedConsentsEpic: AppEpic = (action$) => action$.pipe(
  ofType(putGrantedConsentsCall.type),
  throttleTime(500),
  switchMap(({ payload = {} }) => {
    return from(putGrantedConsents(payload)).pipe(
      switchMap(response => {
        const responseData = snakeToCamel(response.data);
        return of(putGrantedConsentsDone(responseData));
      }),
      catchError((error) => of(putGrantedConsentsFailed(error)))
    );
  })
);

export const postGenerateAuthorizationCodeEpic: AppEpic = (action$) => action$.pipe(
  ofType(postGenerateAuthorizationCodeCall.type),
  throttleTime(500),
  switchMap(({ payload = {} }) => {
    return from(postGenerateAuthorizationCode(payload)).pipe(
      switchMap(response => {
        const responseData = snakeToCamel(response.data);
        return of(postGenerateAuthorizationCodeDone(responseData));
      }),
      catchError((error) => of(postGenerateAuthorizationCodeFailed(error)))
    );
  })
);

export const epics = [
  getGrantedConsentsEpic,
  putGrantedConsentsEpic,
  postGenerateAuthorizationCodeEpic
];
