import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { epicMiddleware, rootEpic } from './epics';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), epicMiddleware],
});

// epicMiddleware.run(rootEpic) should not be called before the middleware has been setup by redux.
// Provide the epicMiddleware instance to createStore() first.
epicMiddleware.run(rootEpic);
